import {useEffect, useState} from 'react';

const useLatestAgentVersion = () => {
  const [agentVersion, setAgentVersion] = useState('latestParcaAgentVersion');
  const [hasFetched, setHasFetched] = useState(false);

  useEffect(() => {
    if (!hasFetched) {
      fetch('https://api.github.com/repos/parca-dev/parca-agent/releases/latest', {
        method: 'GET',
      })
        .then(async response => await response.json())
        .then(data => {
          setAgentVersion(data.tag_name);
          setHasFetched(true);
        });
    }
  }, [hasFetched]);

  return agentVersion;
};

export default useLatestAgentVersion;
