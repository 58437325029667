import {useCallback, useState} from 'react';

export const useRequestAIAccess = () => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const requestAIAccess = useCallback(async ({name, email}: {name: string; email: string}) => {
    setError('');

    setIsLoading(true);

    if (process.env.NODE_ENV === 'development') {
      return;
    }

    await fetch('https://hooks.zapier.com/hooks/catch/11004478/30zbl0a/', {
      method: 'POST',
      body: JSON.stringify({
        name,
        email,
      }),
    })
      .then(() => {
        setSent(true);
      })
      .catch((error: string) => {
        setError(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return {sent, error, isLoading, requestAIAccess};
};
