import {useCallback, useState} from 'react';

export const useRequestFreeTrial = () => {
  const [sent, setSent] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const requestFreeTrial = useCallback(async (email: string) => {
    setError('');

    setIsLoading(true);

    if (process.env.NODE_ENV === 'development') {
      return;
    }

    return await fetch('https://hooks.zapier.com/hooks/catch/11004478/3hq4ooa/', {
      method: 'POST',
      body: email,
    })
      .then(() => setSent(true))
      .catch(error => setError(error))
      .finally(() => setIsLoading(false));
  }, []);

  return {sent, error, isLoading, requestFreeTrial};
};
