import {trackSignUpClick} from 'utils/analytics';

const Kubernetes = () => {
  return (
    <>
      <div className="flex h-auto w-auto flex-col items-start gap-y-3 rounded-md bg-[#272822] py-4 px-5 lg:min-w-[800px]">
        <div className="flex gap-3">
          <div className="h-3 w-3 rounded-full border border-white/20 bg-red-500" />
          <div className="h-3 w-3 rounded-full border border-white/20 bg-yellow-500" />
          <div className="h-3 w-3 rounded-full border border-white/20 bg-green-500" />
        </div>

        <code className="my-3 text-left text-[8px] text-white lg:text-sm">
          $ kubectl apply -f https://api.polarsignals.com/api/manifests.yaml?token=
          <a
            className="italic underline"
            href="https://cloud.polarsignals.com/signup"
            onClick={() => trackSignUpClick('your-token-here-link')}
          >
            &#60;your-token-here&#62;
          </a>
        </code>
      </div>

      <p className="!mb-0 mt-2 text-center text-xs italic">
        If you want to understand what you're applying here and what each individual Kubernetes
        object is for,{' '}
        <a
          className="underline"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.polarsignals.com/docs/setup-collection-kubernetes"
        >
          check out the docs
        </a>
        .
      </p>
    </>
  );
};

export default Kubernetes;
